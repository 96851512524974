<template>
  <div class="flex items-center justify-center">
    <div class="max-w-md w-full p-10 rounded-lg shadow-md bg-white">
      <div class="w-full mt-6">
        <span class="block px-3 font-semibold pb-6">
          <font-awesome-icon icon="check" class="text-6xl p-2 mx-auto my-6 text-green-600
            bg-white rounded-full" />
          <br>
          Ссылка для подтверждения отправлена на почту!
        </span>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailConfModal',
  data: function() {
    return {}
  }
}
</script>
