<template>
  <div
    class="flex flex-col max-w-screen-sm p-3 vld-parent"
    :class="[status === 'confirmed' ? 'justify-center' : 'justify-between']"
  >
    <loading
      :active="status === 'none'"
      :is-full-page="false"
      :loader="'bars'"
      :color="'#00afd3'"
      :height="90"
      :width="90"
      :opacity="0.9"
    />

    <div class="text-left mx-auto">
      <span class="text-lg font-semibold text-primary-500">
        Email:
      </span>
      <span class="text-gray-700">
        {{ email }}
      </span>
    </div>    

    <div class="flex flex-col mx-auto">
      <font-awesome-icon
        :icon="status === 'confirmed' ? ['far', 'check-circle'] : ['far', 'times-circle']"
        :class="[status === 'confirmed' ? 'text-green-700' : 'text-red-700']"
        class="block h-20 text-5xl mx-auto"
      />
      <span v-if="status === 'confirmed'" class="pl-1 text-md">
        Email подтверждён!
      </span>
      <span v-if="status !== 'confirmed'" class="pl-1 text-md text-red-700">
        Email не подтверждён!
      </span>
    </div>

    <div v-if="status !== 'confirmed'" class="flex">
      <BlueButton
        class="flex-1"
        @click.native="sendClick()"
        text="Подтвердить e-mail"
        :loading="requestLoading"
      />
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import BlueButton from '@/components/_partials/BlueButton.vue'
import { mapActions } from 'vuex'

export default {
  name: 'EmailConfirmation',
  components: { Loading, BlueButton },
  data: function() {
    return {
      requestLoading: false
    }
  },
  props: {
    email: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions({
      sendEmailConfirm: 'user/sendEmailConfirm'
    }),
    sendClick() {
      this.requestLoading = true
      this.sendEmailConfirm()
        .then(() => {
          this.$xmodal.open({
            component: require("@/components/account/EmailConfModal.vue").default,
            backgroundColor: '#aaaaaa',
            opacity: '0.5'
          })
        })
        .finally(() => this.requestLoading = false)
    }
  }
}
</script>