var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full mx-auto md:w-4/5 px-2 md:px-6"},[_c('h1',{staticClass:"block text-3xl text-secondary text-left pt-8 pb-3 pr-2 md:pr-4 lg:pr-8",staticStyle:{"line-height":"1.2"}},[_vm._v(" Результаты поиска по запросу: "+_vm._s(_vm.query)+" ")]),(_vm.isLoading)?_c('div',{key:"animation"},[_c('LoadingScreen')],1):_vm._e(),(_vm.query.length < 3)?_c('div',[_vm._v("Слишком короткий поисковый запрос!")]):(!_vm.isLoading && !_vm.results.length)?_c('div',[_vm._v("Ничего не найдено!")]):_vm._e(),(!_vm.isLoading)?_c('div',{key:"content",staticClass:"flex flex-wrap items-stretch mt-9"},_vm._l((_vm.results),function(result){return _c(result.type == 'video' ? 'VideoCard' : 'TestCard',_vm._b({key:result.type + result.name,tag:"component"},'component',{ 
        link: '/' + result.area + '/' + result.type + 's/' + result.id,
        id: result.id,
        title: result.name,
        type: 'single',
        expert: result.expert,
        isAvailable: _vm.isUserHaveAccess
      },false))}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }