<template>
  <div class="bg-white shadow-md pt-4 pb-2 pl-8 pr-4 rounded-lg">
    <div class="max-w-screen-xl m-auto">
      <h2 class="text-2xl pb-3 font-semibold text-primary-500 mx-auto text-left">Избранное</h2>
      <div class="flex flex-wrap">
        <div 
          v-if="videos.length === 0"
          class="h-56 w-full text-center pt-20"
        >
          Список избранного пуст
        </div>
        <VideoCard
          v-for="video in videos"
          :key="video.id"
          :id="video.id"
          :title="video.name"
          :description="video.description"
          :expert="video.expert"
          :link="'/' + video.therapyarea + '/videos/' + video.id"
          :isFavourite="true"
          :isAvailable="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import VideoCard from '@/components/videos/VideoCard.vue'

export default {
  name: 'UserInfo',
  components: {
    VideoCard
  },
  data: function() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      videos: 'videos/getUserFavouriteVideos'
    })
  },
  created: function() {
    this.$store.dispatch('videos/getFavouriteVideos')
      .catch()
      .finally(() => { this.isLoading = false })
  }
}
</script>

<style>
</style>
