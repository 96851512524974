<template>
  <div class="bg-white shadow-md p-4 pb-2 rounded-b-lg rounded-r-lg">
    <div class="max-w-screen-xl m-auto">
      
      <i class="fas fa-user"></i>

      <!-- Данные аккаунта -->
      <div class="border rounded-lg shadow-lg pt-3 px-3">
        <h2 class="text-2xl pl-2 font-semibold text-primary-500 mx-auto text-left">Аккаунт</h2>
        <div class="flex flex-wrap justify-between">
          <div class="flex-grow p-3 w-8/12 ">
            <h2 class="text-lg font-semibold text-primary-500 mx-auto text-left pb-2"> {{ user.name }}</h2>
            <p class="text-left pb-2"><b class="text-gray-700">Специальность:</b> {{ user.speciality.id === 18 ? user.specialityName : user.speciality.name }}</p>
            <p class="text-left pb-2"><b class="text-gray-700">Должность:</b> {{ user.position ? user.position.name : 'Не указана' }}</p>
            <p class="text-left pb-2"><b class="text-gray-700">Город:</b> {{ user.city }}</p>
            <p class="text-left pb-2"><b class="text-gray-700">Место работы:</b> {{ user.workplace.name }}</p>
            <p class="text-left pb-2"><b class="text-gray-700">Наименование учреждения:</b> {{ user.workAddress }}</p>

            <div class="items-start flex space-x-5">
              <BlueButton
                class="flex-1"
                @click.native="editClick()"
                text="Редактировать данные"
              />

              <BlueButton
                class="flex-1"
                @click.native="changePassClick()"
                text="Сменить пароль"
              />

              <BlueButton
                class="flex-1"
                @click.native="logoutClick()"
                text="Выйти из аккаунта"
              />
            </div>
          </div>        

          <EmailConfirmation class="" :email="user.email" :status="emailStatus" />
        </div>
      </div>  

      <!-- Терапевтические области -->
      <div class="border rounded-lg shadow-lg p-3 mt-5">
        <h2 class="text-2xl pl-2 font-semibold text-primary-500 mx-auto text-left">
          Доступные терапевтические области
        </h2>
        <div class="w-full pt-3 px-1 flex flex-wrap items-center m-auto max-w-6xl">
          <TherapyAreaCard
            v-for="therapyarea in user.areas"
            :key="therapyarea.id"
            :imgLink="therapyarea.url_name"
            :url="therapyarea.url_name"
          />
        </div>
      </div>

      <h2 class="pt-3 text-2xl font-semibold text-primary-500 mx-auto text-left">Статистика</h2>
        <div v-if="videosDone != null && testsDone != null" class="flex flex-wrap justify-center">
          <ProgressCircle
            :partsDone="videosDone"
            :partsTotal="videosTotal"
            :drawLable="true"
            lable1="Видео"
            lable2="изучено"
          />
          <!--
          <ProgressCircle 
            :partsDone="24"
            :partsTotal="55"
            :drawLable="true"
            lable1="Библиотеки"
            lable2="изучено"
          /> -->
          <!-- Начато - это те в которых он дошел до конца и завершил с любым результатом -->
          <ProgressCircle
            :partsDone="testsStarted"
            :partsTotal="testsTotal"
            :drawLable="true"
            lable1="Тестов"
            lable2="начато"
          />
          <!-- Пройдено - это те в которых правильных ответов >= 70% -->
          <ProgressCircle 
            :partsDone="testsDone"
            :partsTotal="testsTotal" 
            :drawLable="true"
            lable1="Тестов"
            lable2="пройдено"
          />
        </div>
    </div>
  </div>
</template>

<script>
import TherapyAreaCard from '@/components/landing/TherapyAreaCard.vue'
import ProgressCircle from '@/components/_partials/ProgressCircle.vue'
import BlueButton from '@/components/_partials/BlueButton.vue'
import EmailConfirmation from '@/components/account/EmailConfirmation.vue'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'UserStats',
  components: {
    TherapyAreaCard,
    ProgressCircle,
    BlueButton,
    EmailConfirmation
  },
  data: function() {
    return {
      videosDone: null,
      videosTotal: null,
      testsStarted: null,
      testsDone: null,
      testsTotal: null
    }
  },
  computed: {
    ...mapState({
      'user': state => state.auth.user,
      'emailStatus': state => state.user.emailStatus
    })
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout', 
      getVideosStats: 'videos/getVideosStats', 
      getTestsStats: 'tests/getTestsStats',
      getEmailStatus: 'user/getEmailStatus'
    }),
    editClick() {
      this.$xmodal.open({
        component: import("@/components/account/EditModal.vue"),
        backgroundColor: '#aaaaaa',
        opacity: '0.5'
      })
    },
    changePassClick() {
      this.$xmodal.open({
        component: import("@/components/account/EditPassModal.vue"),
        backgroundColor: '#aaaaaa',
        opacity: '0.5'
      })
    },
    logoutClick() {
      this.logout().then(() => this.$router.replace({ name: 'Main' }))
    }
  },
  created: function() {
    this.getEmailStatus()
    this.getVideosStats()
      .then(response => {
        this.videosDone = response.count
        this.videosTotal = response.total
      })
    this.getTestsStats()
      .then(response => {
        this.testsStarted = response.started
        this.testsDone = response.completed
        this.testsTotal = response.total
      })
  }
}
</script>
