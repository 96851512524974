<template>
  <div class="">
    <div class="container mx-auto max-w-screen-xl px-4">

      <!-- Show loading while auth data loading -->
      <LoadingScreen v-if="isUserLoading" />

      <!-- User profile content -->
      <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">
        <div v-if="user">
          <h1 
            class="block text-3xl text-secondary text-left pt-8 pb-3 pr-2 md:pr-4 lg:pr-8"
            style="line-height: 1.2"
          >
            Личный кабинет
          </h1>
          
          <!-- Для одобренных -->
          <div v-if="isUserActive" class="flex">
            <button
              v-for="tab in tabs"
              v-bind:key="tab.id"
              class="px-5 py-3 rounded-t-lg text-gray-700 focus:border-none"
              v-bind:class="[{'bg-white font-semibold border border-gray-300 border-b-0': currentTab == tab.id }]"
              v-on:click="currentTab = tab.id"
            >
              <font-awesome-icon :icon="tab.icon" class="mr-2"/> {{ tab.title }}
            </button>
          </div>

          <component v-if="isUserActive" :is="currentTab"/>  

          <!-- Для неодобренных -->
          <RegistrationSuccess v-if="!isUserActive" class="w-1/3 m-auto"/>
          <Footer/>
        </div>
      </fade-transition>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import RegistrationSuccess from '@/components/registration/RegistrationSuccess.vue'

import UserStats from '@/components/account/UserStats.vue'
import UserFavorites from '@/components/account/UserFavorites.vue'
import UserRecommended from '@/components/account/UserRecommended.vue'

import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import { FadeTransition } from 'vue2-transitions'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Account',
  components: {
    LoadingScreen,
    FadeTransition,
    RegistrationSuccess,
    Footer,    
    UserFavorites,
    UserRecommended,
    UserStats
  },
  data: function() {
    return {
      currentTab: 'UserStats',
      tabs: [
        {
          id: 'UserStats',
          icon: 'user',
          title: 'Пользователь'
        },
        {
          id: 'UserFavorites',
          icon: 'star',
          title: 'Избранное'
        },
        {
          id: 'UserRecommended',
          icon: 'thumbs-up',
          title: 'Рекомендованное'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isUserLoading: 'auth/isUserLoading',
      isUserActive: 'auth/isUserActive'
    }),
    ...mapState({
      'user': state => state.auth.user
    })
  },
  methods: {
    openTab(tab) {
      this.currentTab = tab;
    }
  },
  watch: {
    isUserLoading: function() {
      if (!this.user && !this.isUserLoading) this.$router.replace({ name: 'Main' })
    }
  }
}
</script>

<style scoped>
  .active {
  }
</style>
