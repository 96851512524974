<template>
  <!--<fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">-->
  <div class="w-full mx-auto md:w-4/5 px-2 md:px-6">
    <!-- Loading screen -->
    <h1 
      class="block text-3xl text-secondary text-left pt-8 pb-3 pr-2 md:pr-4 lg:pr-8"
      style="line-height: 1.2"
    >
      Результаты поиска по запросу: {{ query }}
    </h1>
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <div v-if="query.length < 3">Слишком короткий поисковый запрос!</div>
    <div v-else-if="!isLoading && !results.length">Ничего не найдено!</div>

    <!-- Result -->
    <div v-if="!isLoading" class="flex flex-wrap items-stretch mt-9" key="content">
      <component
        v-for="result in results"
        :key="result.type + result.name"
        :is="result.type == 'video' ? 'VideoCard' : 'TestCard'"
        v-bind="{ 
          link: '/' + result.area + '/' + result.type + 's/' + result.id,
          id: result.id,
          title: result.name,
          type: 'single',
          expert: result.expert,
          isAvailable: isUserHaveAccess
        }"
      >
      </component>
    </div>
  </div>
  <!--</fade-transition>-->
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import VideoCard from '@/components/videos/VideoCard.vue'
import PublicationCard from '@/components/publications/PublicationCard.vue'
import TestCard from '@/components/tests/TestCard.vue'

export default {
  name: 'Search',
  components: {
    VideoCard,
    TestCard,
    PublicationCard,
    LoadingScreen,
    FadeTransition
  },
  computed: {
    ...mapState({
      query: state => state.search.query,
      results: state => state.search.results,
      isLoading: state => state.search.isLoadingб

    }),
    ...mapGetters({
      isUserHaveAccess: 'isUserHaveAccess'
    })
  }
}
</script>
