<template>
  <button
		type="button"
		class="w-full md:w-1/3 inline-flex items-center justify-center mt-3 rounded-md py-2
		text-gray-200 bg-primary-600 border-primary-600 font-semibold border-2 hover:text-white
		hover:bg-primary-400 hover:border-primary-400"
		:disabled='loading'
	>
		<svg v-if="loading" class="animate-spin h-6 w-6" viewBox="0 0 24 24" fill="none">
			<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
			<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
		</svg>
		<span v-if="!loading">{{ text }}</span>
	</button>
</template>

<script>
export default {
	name: 'BlueButton',
	props: {
		text: {
			type: String,
			required: true
		},
		link: String,
		loading: {
			type: Boolean,
			default: false
		}
	}
}
</script>